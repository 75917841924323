import React, { useState } from "react"
import {
  Disclosure,
  Transition,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"
import SEO from "../components/seo"

const WorkPage = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <div className="mx-auto">
        <main className="flex flex-col justify-center items-center">
          <SEO title="Work" />
          {/* Header */}
          <div className="relative lg:max-w-[1400px] w-full flex flex-col items-center justify-center rounded-b-[15px] pt-[32px] lg:pt-[48px] pb-[96px] px-[16px] lg:px-0 gap-[36px] lg:gap-[96px] relative overflow-hidden">
            <div
              style={{ maxWidth: 750 }}
              className="lg:absolute lg:block hidden top-0 right-0 z-0"
            >
              <StaticImage
                src="../images/rec_head_top.png"
                alt="bg rectangle"
                className=""
              />
            </div>
            <div
              style={{ maxWidth: 750 }}
              className="lg:absolute lg:block hidden bottom-0 left-0 z-0"
            >
              <StaticImage
                src="../images/rec_head_bottom.png"
                alt="bg rectangle"
                className=""
              />
            </div>

            <div className="w-full flex justify-between items-center lg:px-[48px] z-[1]">
              <div className="flex justify-center lg:justify-start">
                <StaticImage
                  src="../images/bb_logo2.png"
                  alt="BBEnergy.AI logo"
                  style={{ maxWidth: 220 }}
                />
              </div>

              {/* Burger Icon for Mobile */}
              <div className="lg:hidden relative z-[100]">
                <button
                  onClick={() => setMenuOpen(!menuOpen)}
                  className="focus:outline-none"
                >
                  <svg
                    className="w-8 h-8 text-[#090A0B]"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={
                        menuOpen
                          ? "M6 18L18 6M6 6l12 12"
                          : "M4 6h16M4 12h16M4 18h16"
                      }
                    ></path>
                  </svg>
                </button>

                {/* Menu for Mobile */}
                <div
                  className={`${
                    menuOpen ? "flex" : "hidden"
                  } absolute right-0 top-[60px] flex-col bg-white p-4 shadow-lg rounded-lg gap-4 z-[100]`}
                >
                  <ScrollLink
                    to="product"
                    smooth={true}
                    duration={500}
                    className="text-[20px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    Product
                  </ScrollLink>
                  <ScrollLink
                    to="faqs"
                    smooth={true}
                    duration={500}
                    className="text-[20px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    FAQs
                  </ScrollLink>
                  <ScrollLink
                    to="pricing"
                    smooth={true}
                    duration={500}
                    className="text-[20px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    Pricing
                  </ScrollLink>
                  <Link
                    to="/learn"
                    className="text-[20px] font-medium text-[#090A0B] cursor-pointer w-[150px]"
                  >
                    Personal
                  </Link>
                </div>
              </div>

              {/* Menu Items for Desktop */}
              <div className="hidden lg:flex gap-[64px]">
                <ScrollLink
                  to="product"
                  smooth={true}
                  duration={500}
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  Product
                </ScrollLink>
                <ScrollLink
                  to="faqs"
                  smooth={true}
                  duration={500}
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  FAQs
                </ScrollLink>
                <ScrollLink
                  to="pricing"
                  smooth={true}
                  duration={500}
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  Pricing
                </ScrollLink>
                <Link
                  to="/learn"
                  className="text-[20px] font-medium text-[#090A0B] cursor-pointer"
                >
                  Personal
                </Link>
              </div>
            </div>

            <div className="w-full flex gap-[96px] justify-between items-center flex-col lg:flex-row lg:px-[48px] lg:z-[1]">
              <div className="flex flex-col items-start gap-[36px] w-full max-w-[568px]">
                <h1 className="text-[#141414] text-[42px] lg:text-[50px] font-semibold leading-tight lg:z-[1]">
                  Engage Your Team with Personalized and Effective Microlearning
                </h1>
                <p className="text-[#141414] text-[22px] font-medium leading-[150%] lg:z-[1]">
                  Transform workplace learning into a daily habit with engaging,
                  bite-sized lessons tailored to each individual on your team.
                </p>
                <div className="flex flex-col gap-[10px]">
                  <Link
                    to="/work-beta"
                    className="py-[12px] px-[16px] text-center text-white bg-[#DB28A9] rounded-[8px] font-semibold font-general-sans-semibold"
                  >
                    Onboard Your Team Today
                  </Link>
                </div>
              </div>
              <div className="max-w-[300px] lg:mr-[50px]">
                <StaticImage src="../images/lesson-screen.png" alt="mockup" />
              </div>
            </div>
          </div>

          {/* books section */}
          <div className="w-full flex flex-col items-center justify-center gap-4 bg-[#F9FAFB] py-[96px]">
            <p className="font-semibold text-lg text-center mb-4">
              Bite-size personalized learning based on content from top minds
              like
            </p>
            <div className="w-full lg:max-w-[1400px] grid grid-cols-4 md:grid-cols-8 gap-x-auto gap-y-4 md:gap-y-0">
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/build_trap.png"
                  alt="Image 1"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/sci_adv.png"
                  alt="Image 2"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/cold_start.png"
                  alt="Image 3"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/chasm.png"
                  alt="Image 4"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/pred_irr.png"
                  alt="Image 5"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/blue_ocean.png"
                  alt="Image 6"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/hbr.png"
                  alt="Image 7"
                  layout="constrained"
                />
              </div>
              <div className="max-w-[90px] mx-auto">
                <StaticImage
                  src="../images/yc.png"
                  alt="Image 8"
                  layout="constrained"
                />
              </div>
            </div>
          </div>

          {/* how it works */}
          <div
            id="product"
            className="w-full flex flex-col items-center justify-center py-[96px] px-[16px] gap-[64px] bg-white"
          >
            <div className="flex flex-col items-center justify-center gap-[24px]">
              <div className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0] pill-bg pill-shadow">
                How Big Brain Energy Works
              </div>
              <h2 className="text-[#101828] text-4xl font-semibold leading-tight text-center">
                The Duolingo Learning Experience For Work
              </h2>
            </div>

            <div className="flex flex-col gap-8 w-full max-w-[1400px]">
              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure defaultOpen>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Set up your unique learning pathway
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Employees select from dozens of pre-set course
                                starters and customise the course for their
                                specific skill level, job needs and career
                                goals.
                              </p>
                              <p>
                                Our AI cuts and stitches the relevant
                                educational content in our database to create a
                                unique 60-day lesson plan tailored to them.
                              </p>
                              <a
                                href="https://bbenergy.notion.site/Q3-2024-Learning-Topics-on-Big-Brain-Energy-1d69d9fd0ee94d01996b7ac2f99cf735?pvs=4"
                                target="_blank"
                                className="text-[#DB28A9]"
                              >
                                Q3 2024 course starters available on Big Brain
                                Energy
                              </a>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/create.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Complete short lessons
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Employees take short lessons from their learning
                                pathway on our mobile app or web.
                              </p>
                              <p>
                                Lessons take no more than 15 minutes to
                                complete, making it easy to fit learning into a
                                daily routine.
                              </p>
                              <p>
                                Each lesson is assembled by AI to create an
                                engaging combination of text, image and video
                                content, enhanced with real-world case studies
                                and quizzes to test what you’ve learned.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/complete.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Stay motivated as a team
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Employees automatically share progress on a
                                dedicated Slack channel and show off their day
                                streak on the company leaderboard.
                              </p>
                              <p>
                                Big Brain Energy helps every team member stay
                                motivated and feel part of a wider learning
                                culture at your company.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/motivate.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Save your progress
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Once you complete a course, you receive a PDF
                                summary of what you’ve learnt, recommendations
                                for further reading and suggestions for what to
                                learn next.
                              </p>
                              <p>
                                Content from all completed lessons is always
                                available to refer back to in the Big Brain
                                Energy app.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/save.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-[#F9FAFB] rounded-xl w-full">
                <Disclosure>
                  {({ open }) => (
                    <div className="flex flex-col lg:flex-row justify-between">
                      <div>
                        <DisclosureButton className="flex gap-2 max-w-[500px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            className={`transform transition-transform duration-200 ${
                              open ? "rotate-0" : "rotate-180"
                            }`}
                          >
                            <path
                              d="M12.0446 9.15282L18.9698 16.0789C19.0394 16.1487 19.1222 16.204 19.2132 16.2417C19.3043 16.2795 19.4019 16.2989 19.5004 16.2989C19.599 16.2989 19.6966 16.2795 19.7876 16.2417C19.8787 16.204 19.9614 16.1487 20.031 16.0789L12.0446 9.15282ZM12.0446 9.15282L12.0004 9.10863L11.9562 9.15282M12.0446 9.15282H11.9562M11.9562 9.15282L5.03104 16.0789C4.89031 16.2197 4.69944 16.2987 4.50042 16.2987C4.30139 16.2987 4.11052 16.2197 3.96979 16.0789C3.82906 15.9382 3.75 15.7473 3.75 15.5483C3.75 15.3493 3.82906 15.1584 3.96979 15.0177L11.9562 9.15282ZM19.014 16.0348L19.014 16.0348L12.0446 9.06444L12.0004 9.02024L11.9562 9.06444L4.98684 16.0348C4.85784 16.1638 4.68286 16.2362 4.50042 16.2362C4.31797 16.2362 4.143 16.1638 4.01399 16.0348C3.88498 15.9057 3.8125 15.7308 3.8125 15.5483C3.8125 15.3659 3.88498 15.1909 4.01399 15.0619L11.514 7.56189L11.514 7.56186C11.5779 7.49794 11.6537 7.44723 11.7371 7.41264C11.8206 7.37804 11.9101 7.36023 12.0004 7.36023C12.0908 7.36023 12.1802 7.37804 12.2637 7.41264C12.3471 7.44723 12.423 7.49794 12.4868 7.56186L12.4868 7.56189L19.9869 15.0619L19.9869 15.0619C20.0508 15.1258 20.1015 15.2016 20.1361 15.285C20.1707 15.3685 20.1885 15.458 20.1885 15.5483C20.1885 15.6387 20.1707 15.7281 20.1361 15.8116C20.1015 15.8951 20.0508 15.9709 19.9869 16.0347L19.9868 16.0348C19.923 16.0987 19.8471 16.1494 19.7637 16.184C19.6802 16.2186 19.5908 16.2364 19.5004 16.2364C19.4101 16.2364 19.3206 16.2186 19.2371 16.184C19.1537 16.1494 19.0779 16.0987 19.014 16.0348Z"
                              fill="#090A0B"
                              stroke="#090A0B"
                              stroke-width="0.125"
                            />
                          </svg>
                          <h2 className="text-xl lg:text-2xl text-left font-semibold">
                            Stay hungry, stay foolish
                          </h2>
                        </DisclosureButton>
                        <Transition
                          enter="transition duration-200 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <DisclosurePanel className="mt-[30px] flex gap-2">
                            <div className="flex flex-col gap-[16px] text-[18px] max-w-[500px]">
                              <p>
                                Big Brain Energy is designed to be an always-on,
                                low touch and fun tool for your team.
                              </p>
                              <p>
                                At the end of every 60-day course, the cycle
                                resets and you keep on learning. You can choose
                                to follow on from the last course with more
                                advanced concepts, or study something entirely
                                new.
                              </p>
                            </div>
                          </DisclosurePanel>
                        </Transition>
                      </div>

                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel>
                          <div style={{ maxWidth: `630px` }}>
                            <StaticImage
                              src="../images/stay.png"
                              alt="Section image"
                            />
                          </div>
                        </DisclosurePanel>
                      </Transition>
                    </div>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* faqs section */}
          <div
            id="faqs"
            className="w-full flex flex-col items-center justify-center py-[96px] px-[16px] lg:px-[96px] gap-[64px] bg-[#F9FAFB]"
          >
            <div className="flex flex-col items-center justify-center gap-[24px]">
              <div className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0] pill-bg pill-shadow">
                FAQs
              </div>
              <h2 className="text-[#101828] text-4xl font-semibold leading-tight text-center">
                Some Questions You Might Have
              </h2>
            </div>

            <div className="flex flex-col items-center justify-center gap-[16px] w-full">
              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          What is Big Brain Energy?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Big Brain Energy is a workplace learning platform
                            offering fun, bite-sized daily lessons on work
                            topics, using AI to tailor courses to each team
                            member’s needs.
                          </p>
                          <p>
                            We’re bringing the Duolingo experience to workplace
                            learning.
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          What can my team learn on Big Brain Energy?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Here is the full list of topics that are currently
                            available on the platform. These topics make up the
                            starter templates from which every individual user
                            creates unique personalised courses.
                          </p>
                          <a
                            href="https://bbenergy.notion.site/Q3-2024-Learning-Topics-on-Big-Brain-Energy-1d69d9fd0ee94d01996b7ac2f99cf735?pvs=4"
                            target="_blank"
                            className="text-[#DB28A9]"
                          >
                            Q3 2024 course starters available on Big Brain
                            Energy
                          </a>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          Where does the educational content come from?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Our team of experts manually curates all content
                            taught on Big Brain Energy. This includes the best
                            business books, most authoritative articles, courses
                            and video lessons, as well as relevant business case
                            studies.
                          </p>
                          <p>
                            We carefully curate and label all of these sources
                            to make a knowledge bank for each topic covered on
                            the platform.
                          </p>
                          <p>
                            You can see all the sources we use here:{" "}
                            <a
                              href="https://bbenergy.notion.site/Q3-2024-Learning-Topics-on-Big-Brain-Energy-1d69d9fd0ee94d01996b7ac2f99cf735?pvs=4"
                              target="_blank"
                              className="text-[#DB28A9]"
                            >
                              Q3 2024 course starters available on Big Brain
                              Energy
                            </a>
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          Where does the AI come in?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Our AI never “generates” content and{" "}
                            <b>
                              all information in every lesson is labelled and
                              referenced to its original human source.
                            </b>
                          </p>
                          <p>
                            When creating a unique course for a user, our AI
                            accesses the manually curated human knowledge bank
                            and selects the relevant source material to use in
                            that course. It then arranges the material into a
                            60-day learning plan.
                          </p>
                          <p>
                            For every daily lesson, the AI selects and stitches
                            together the relevant content from multiple sources
                            to turn it into an engaging 15-minute learning
                            experience. But the insights and learnings are all
                            human.
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          As a manager, can I track my team’s progress?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Admin users of Big Brain Energy learning spaces have
                            visibility over individual team members’ engagement.
                            This includes (1) their learning history, (2) what
                            they’re currently learning, (3) their 30-day
                            engagement with the app.
                          </p>
                          <p>
                            The dashboard also provides various useful
                            aggregated analytics to see how your whole team is
                            doing and analyse the value added by Big Brain
                            Energy to your company.
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>

              <div className="px-[16px] py-[24px] lg:p-[32px] bg-white rounded-xl border border-[#F0F0F0] w-full max-w-[748px]">
                <Disclosure>
                  {({ open }) => (
                    <>
                      <DisclosureButton className="w-full flex justify-between items-center">
                        <h2 className="text-lg text-left font-semibold">
                          Is it easy to get started?
                        </h2>
                        {!open ? (
                          <StaticImage
                            src="../images/plus.png"
                            alt="Plus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        ) : (
                          <StaticImage
                            src="../images/minus.png"
                            alt="Minus icon"
                            style={{ maxWidth: `24px` }}
                          />
                        )}
                      </DisclosureButton>
                      <Transition
                        enter="transition duration-200 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <DisclosurePanel className="mt-2 flex flex-col gap-2 text-[#71717A] text-[18px]">
                          <p>
                            Very! Just create your company account and share the
                            link company-wide for employees to sign up. For
                            employees, getting set up on their first course
                            takes 2 minutes.
                          </p>
                          <p>
                            (Plus, we offer an API to create new employee
                            accounts as part of onboarding)
                          </p>
                        </DisclosurePanel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* pricing */}
          <div
            id="pricing"
            className="w-full relative flex flex-col items-center justify-center py-[96px] px-[16px] lg:px-[96px] gap-[64px] bg-white"
          >
            <div
              style={{ maxWidth: `420px` }}
              className="lg:absolute lg:block hidden bottom-0 left-0 z-0"
            >
              <StaticImage
                src="../images/rec_pricing_left.png"
                alt="bg rectangle"
                className=""
              />
            </div>
            <div
              style={{ maxWidth: `550px` }}
              className="lg:absolute lg:block hidden bottom-0 right-0 z-0"
            >
              <StaticImage
                src="../images/rec_pricing_right.png"
                alt="bg rectangle"
                className=""
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-[24px]">
              <div className="w-fit text-[16px] font-bold p-[12px] rounded-[4px] text-[#892FE0] pill-bg pill-shadow">
                Pricing
              </div>
              <h2 className="text-[#101828] text-4xl font-semibold leading-tight text-center">
                Upskill Your Team Today
              </h2>
            </div>
            <div className="flex flex-col lg:flex-row justify-center gap-8 lg:gap-[230px] items-center">
              <div className="w-fit rounded-2xl p-6 text-center bg-white ring-1 ring-inset ring-gray-900/5 z-[1]">
                <div className="w-full px-2 flex flex-col gap-4 justify-center items-center">
                  <div className="flex items-center gap-2">
                    <p className="text-5xl font-bold text-gray-900">£10</p>
                    <div className="flex flex-col gap-1">
                      <p className="text-left text-sm font-semibold text-[#71717A]">
                        PER ACTIVE LEARNER
                      </p>
                      <p className="text-left text-sm font-semibold text-[#71717A]">
                        PER MONTH
                      </p>
                    </div>
                  </div>
                  <ul
                    role="list"
                    className="w-full grid grid-cols-1 gap-4 leading-6 my-6"
                  >
                    <li className="flex gap-x-3 text-left items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-5 text-accent3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      Access to all content and learning features
                    </li>
                    <li className="flex gap-x-3 items-center text-left">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-5 text-accent3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      Learn on web and mobile
                    </li>
                    <li className="flex gap-x-3 text-left items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-5 text-accent3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      Company leaderboards and Slack integration
                    </li>
                    <li className="flex gap-x-3 text-left items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-5 text-accent3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      User management and analytics for admins
                    </li>
                    <li className="flex gap-x-3 text-left items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-5 text-accent3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      API to integrate into your LMS/HR systems
                    </li>
                    <li className="flex gap-x-3 text-left items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2.5}
                        stroke="currentColor"
                        className="w-6 h-5 text-accent3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12.75l6 6 9-13.5"
                        />
                      </svg>
                      In-app customer support
                    </li>
                  </ul>
                  <Link
                    to="/work-beta"
                    className="py-[12px] px-[16px] w-full text-center text-white bg-[#DB28A9] rounded-[8px] font-semibold font-general-sans-semibold"
                  >
                    Get Started
                  </Link>
                  <p className="text-sm leading-5 text-gray-600">
                    <span className="font-semibold text-[#DB28A9]">Free</span>{" "}
                    while Big Brain Energy is in closed beta
                  </p>
                </div>
              </div>
              <div style={{ maxWidth: `400px` }}>
                <StaticImage src="../images/quiz-screen.png" alt="mockup" />
              </div>
            </div>
          </div>
        </main>

        <footer>
          <div className="flex justify-center text-[#475467] text-center text-[16px] lg:mt-[12px] mb-[10px] px-[32px] lg:px-0">
            <p>
              Big Brain Energy.AI © {new Date().getFullYear()}. Made with&nbsp;
              <a
                href="https://www.tiktok.com/@yamaliavkaa/video/7157416182616820997"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex"
              >
                🍺
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://www.tiktok.com/@gabinlondon/video/7059435150106496262?q=sunset%20primrose%20hill%20romantic&t=1716209988363"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex"
              >
                ❤️
              </a>
              &nbsp;in London 🇬🇧
            </p>
          </div>
          <div className="flex justify-center text-[#475467] text-center text-[16px] mb-[32px] px-[32px] lg:px-0">
            <p>
              See our&nbsp;
              <a
                href="/terms"
                target="_blank"
                className="pb-[0px] border-b border-[#141414] inline-flex text-black"
              >
                Terms and Conditions
              </a>
              &nbsp;and&nbsp;
              <a
                href="/privacy"
                target="_blank"
                className="text-black pb-[0px] border-b border-[#141414] inline-flex"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

export default WorkPage
